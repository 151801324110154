// import Overlay from './overlay';
import Navbar from './navbar'
import ClientsSlider from './clientsSlider'
import Underline from "./underline";
import ScrollToAnchor from "./scroll-to-anchor";

export default class App {

    constructor() {

        // init navbar
        this.navbar = new Navbar('#header');

        // init overlay
        // this.overlay = new Overlay('#overlay');
        // this.overlay.bind('.trigger-overlay');

        // init underline
        this.underline = new Underline('nav');
        this.underline.bind('ul li a');

        // init scroll-to-anchor
        this.scroll_to_anchor = new ScrollToAnchor('.js-scroll-to-anchor');
    }

}
