$(function () {

  let lastScrollPos, debounce = 0;

  $("#clients-slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          adaptiveHeight: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  });

  $("#overlay-sliders").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false
  });

  $(document).on("click", "[data-toggle='case-study']", function (e) {
    const target = $(this).attr("href");
    const index = $(target).data("slick-index") || 0;
    $("body").addClass("case-study-will-open").one("transitionend", function () {
      $(this).addClass("case-study-did-open");
      if($(window).width() <= 800) {
        window.scrollTo(0, 0);
      }
    });
    $("#overlay-sliders").slick("slickGoTo", index, true);
    $("#overlay").addClass("fade-in");
    $("#clients-slider").slick('slickPause');
    lastScrollPos = $(window).scrollTop();
    e.preventDefault();

  });


  $(document).on("click", "[data-dismiss='case-study']", function (e) {
    $("#overlay").addClass("fade-out").one("transitionend", function () {
      var self = this;
      $("body").removeClass("case-study-will-open case-study-did-open");
      setTimeout(function () {
        $(self).removeClass("fade-in fade-out");
        $("#clients-slider").slick('slickPlay');
        if($(window).width() <= 800) {
          window.scrollTo(0, lastScrollPos);
        }
      }, 10);
    });
    e.preventDefault();
  });


  // Move case studies to different position depending on screen size
  $(window).on("resize", function () {
    clearTimeout(debounce);
    debounce = setTimeout(function () {
      if($(window).width() <= 800) {
        $('#overlay').appendTo('#case-studies-mobile');
      } else {
        $('#overlay').insertAfter('#clients-slider');
      }
    }, 100);
  }).resize();
})
