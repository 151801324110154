class Query {

    constructor(param) {
        if (param instanceof String || typeof param === 'string') {
            this.elements = document.querySelectorAll(param);
            return;
        }
        if (param instanceof Array) {
            this.elements = param;
            return;
        }
        throw new Error('Query object could not be initialized: invalid parameter.');
    }

    event(type, func) {
        for (let element of this.elements) {
            element.addEventListener(type, func);
        }
        return this;
    }

    show() {
        for (let element of this.elements) {
            element.style.display = 'block';
        }
        return this;
    }

    hide() {
        for (let element of this.elements) {
            element.style.display = 'none';
        }
        return this;
    }

    animate(from, to) {
        for (let element of this.elements) {
            for (let key in from) {
                if (!from.hasOwnProperty(key)) continue;
                element.style[key] = from[key];
            }
            element.style.transition = 'all 0.2s linear';
            setTimeout(() => {
                for (let key in to) {
                    if (!to.hasOwnProperty(key)) continue;
                    element.style[key] = to[key];
                }
            }, 0);
        }
        return this;
    }

    fadeIn() {
        for (let element of this.elements) {
            element.style.opacity = '0';
            element.style.transition = 'all 0.2s linear';
            setTimeout(() => {
                element.style.opacity = '1';
            }, 0);
        }
        return this;
    }

    fadeOut() {
        for (let element of this.elements) {
            element.style.opacity = '0';
            setTimeout(() => {
                element.style.display = 'none';
                element.style.opacity = '1';
            }, 200);
        }
        return this;
    }

    /**
     * @param selector
     * @returns {Query}
     */
    find(selector) {
        let elements = [];
        for (let element of this.elements) {
            let finds = element.querySelectorAll(selector);
            elements = elements.concat([...finds]);
        }
        return new Query(elements);
    }

    first() {
        return this.elements[0];
    }

    last() {
        return this.elements[this.elements.length - 1];
    }

    count() {
        return this.elements.length;
    }

    style(key, value) {
        for (let element of this.elements) {
            element.style[key] = value;
        }
        return this;
    }

    addClass(name) {
        for (let element of this.elements) {
            if (element.classList) {
                element.classList.add(name);
            } else {
                let classes = element.className.split(" ");
                let i = classes.indexOf(name);
                if (i < 0) {
                    classes.push(name);
                }
                element.className = classes.join(" ");
            }
        }
        return this;
    }

    removeClass(name) {
        for (let element of this.elements) {
            if (element.classList) {
                element.classList.remove(name);
            } else {
                let classes = element.className.split(" ");
                let i = classes.indexOf(name);
                if (i >= 0) {
                    classes.splice(i, 1);
                }
                element.className = classes.join(" ");
            }
        }
        return this;
    }

    toggleClass(name) {
        for (let element of this.elements) {
            if (element.classList) {
                element.classList.toggle(name);
            } else {
                let classes = element.className.split(" ");
                let i = classes.indexOf(name);
                if (i >= 0) {
                    classes.splice(i, 1);
                } else {
                    classes.push(name);
                }
                element.className = classes.join(" ");
            }
        }
        return this;
    }

    hasClass(name) {
        let element = this.first();
        if (element) {
            let classes = element.className.split(" ");
            return classes.indexOf(name) >= 0;
        }
        return false;
    }

}

/**
 * @param identifier
 * @returns {Query}
 */
export default function $(identifier) {
    return new Query(identifier);
}