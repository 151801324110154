import Loop from './loop';
import $ from './vendor/jquery';

export default class NavigationBar {

    constructor(selector) {
        this.navbar = $(selector);
        this.bind();

        this.prepareMobile();
        this.prepareNoScroll();
    }

    bind() {
        let lastPagePos = 0;
        let offset = 0;
        let barIsDynamic = false;
        let barIsVisible = false;

        new Loop(() => {

            let pagePos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            // mobile
            if (width <= 960) {
                if (pagePos <= 14) {
                    this.navbar.removeClass('sticky').removeClass('visible');
                } else {
                    this.navbar.addClass('sticky').addClass('visible');
                }
                return;
            }

            // not mobile
            if (pagePos > 100) {

                // make bar dynamic if not already
                if (!barIsDynamic) {
                    this.navbar.addClass('sticky');
                    barIsDynamic = true;
                }

                if (barIsVisible && pagePos > lastPagePos) {
                    if (offset < 0) offset = 0;
                    offset += pagePos - lastPagePos;
                } else if (!barIsVisible && pagePos < lastPagePos) {
                    if (offset > 0) offset = 0;
                    offset += pagePos - lastPagePos;
                }

                if (offset > 100) {
                    this.navbar.removeClass('visible');
                    barIsVisible = false;
                    offset = 0;
                } else if (offset < -100) {
                    this.navbar.addClass('visible');
                    barIsVisible = true;
                    offset = 0;
                }

            } else if (pagePos <= 14) {
                this.navbar.removeClass('sticky').removeClass('visible');
                barIsDynamic = false;
            }

            lastPagePos = pagePos;

        }).run();
    }

    prepareMobile() {
        this.navbar.find('#mobile-menu-btn').click(() => {
            this.navbar.toggleClass('active');
            $('body').toggleClass('no-scroll');
        });

        this.navbar.find('nav').click(() => {
            this.navbar.removeClass('active');
            $('body').removeClass('no-scroll');
        });
    }

    prepareNoScroll() {
        $(document).on('scroll mousewheel touchmove', '.no-scroll', function (e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        });
    }
}
