export default class Loop {

    constructor(draw) {
        this.draw = draw;
    }

    run(time) {
        window.requestAnimationFrame(this.run.bind(this));
        this.draw(time || new Date());
    }

}