import App from './modules/app';

let app = new App();
window.app = app;


function isTouchDevice() {
    return !!('ontouchstart' in window) || !!('msmaxtouchpoints' in window.navigator);
}

if (isTouchDevice()) {
    $('body').addClass('touch');
}

$(document).on('touchstart', 'a, button', function () {
    let self = $(this);
    let timeout = setTimeout(function () {
        self.addClass('active');
    }, 50);

    $(this).on('touchmove', function () {
        clearTimeout(timeout);
        self.removeClass('active');
    });

    $(this).on('touchend', function () {
        clearTimeout(timeout);
        self.removeClass('active');
        $(this).off('touchend');
        $(this).off('touchmove');
    });
});