import $ from './query';

export default class Underline {

    constructor(selector) {
        this.element = $(selector);

        this.underline = document.createElement('div');
        this.underline.style.display = 'none';
        this.underline.style.height = '2px';
        this.underline.style.background = '#47a3da';
        this.underline.style.position = 'absolute';
        this.underline.style.top = '100%';
        this.underline.style.transition = 'all .2s';
        this.element.first().appendChild(this.underline);
    }

    bind(selector) {

        this.element.event('mouseover', () => {
            this.underline.style.display = 'block';
        }).event('mouseleave', () => {
            this.underline.style.display = 'none';
        });

        this.element.find(selector).event('mouseover', (e) => {
            let pl = parseInt(window.getComputedStyle(e.currentTarget, null).getPropertyValue('padding-left'), 10);
            let pr = parseInt(window.getComputedStyle(e.currentTarget, null).getPropertyValue('padding-right'), 10);
            this.underline.style.width = (e.currentTarget.clientWidth - pl - pr) + 'px';
            this.underline.style.left = (e.currentTarget.offsetLeft + pl) + 'px';
        });
    }

}