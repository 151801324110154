export default class ScrollToAnchor {

constructor(selector) {
  $(selector).click(function (e) {

      var href = $(this).attr("href");

      if (href.indexOf("#") != -1) {
        // The link has a hash
        var hash = href.substr(href.indexOf("#"));
        var $hash = $(hash);
        if($hash.length) {
          e.preventDefault();

          $('body').css('overflow', '');
          let section = $hash;
          $('html, body').animate({
              scrollTop: $(section).offset().top,
          }, 1000);
        }
      }
    });
  }

}
